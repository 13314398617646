<template>
    <div class="new-toast">
        {{title}}
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: {
        title: String
    },
    methods: {

    }
}
</script>
<style scoped>

</style>