import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        count: 0,
        showToast: false,
        showTitle: ""
    },
    mutations: {
        increment(state) {
            state.count++
        },
        showToast(state, title = null) {
            state.showToast = !state.showToast
            state.showTitle = title
        },
        hideToast(state) {
            state.showToast = false
        }
    }
})

export default store