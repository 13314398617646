export const citys = [{
	"ch": "A",
	"ul": [{
		"sortType": "sort2",
		"text": "阿拉善盟",
		"value": 944,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "鞍山市",
		"value": 974,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "安庆市",
		"value": 1320,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "安阳市",
		"value": 1718,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "阿坝藏族羌族自治州",
		"value": 2446,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "安顺市",
		"value": 2529,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "阿里地区",
		"value": 2806,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "安康市",
		"value": 2921,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "阿克苏地区",
		"value": 3166,
		"en": "A"
	}, {
		"sortType": "sort2",
		"text": "阿勒泰地区",
		"value": 3222,
		"en": "A"
	}]
}, {
	"ch": "B",
	"ul": [{
		"sortType": "sort2",
		"text": "北京市",
		"value": 2,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "保定市",
		"value": 607,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "包头市",
		"value": 845,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "巴彦淖尔市",
		"value": 904,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "本溪市",
		"value": 990,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "白山市",
		"value": 1105,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "白城市",
		"value": 1118,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "蚌埠市",
		"value": 1290,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "亳州市",
		"value": 1378,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "滨州市",
		"value": 1648,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "北海市",
		"value": 2149,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "百色市",
		"value": 2177,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "巴中市",
		"value": 2436,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "毕节地区",
		"value": 2556,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "保山市",
		"value": 2631,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "宝鸡市",
		"value": 2842,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "白银市",
		"value": 2954,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "博尔塔拉蒙古自治州",
		"value": 3152,
		"en": "B"
	}, {
		"sortType": "sort2",
		"text": "巴音郭楞蒙古自治州",
		"value": 3156,
		"en": "B"
	}]
}, {
	"ch": "C",
	"ul": [{
		"sortType": "sort2",
		"text": "潮州市",
		"value": 168,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "常州市",
		"value": 238,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "承德市",
		"value": 651,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "沧州市",
		"value": 663,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "长治市",
		"value": 733,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "赤峰市",
		"value": 859,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "长春市",
		"value": 1064,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "滁州市",
		"value": 1340,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "巢湖市",
		"value": 1364,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "池州市",
		"value": 1383,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "长沙市",
		"value": 1962,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "常德市",
		"value": 2024,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "郴州市",
		"value": 2046,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "崇左市",
		"value": 2215,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "重庆市",
		"value": 2251,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "成都市",
		"value": 2294,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "楚雄彝族自治州",
		"value": 2675,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "昌都地区",
		"value": 2751,
		"en": "C"
	}, {
		"sortType": "sort2",
		"text": "昌吉回族自治州",
		"value": 3144,
		"en": "C"
	}]
}, {
	"ch": "D",
	"ul": [{
		"sortType": "sort2",
		"text": "东莞市",
		"value": 166,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "大同市",
		"value": 715,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "大连市",
		"value": 963,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "丹东市",
		"value": 997,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "大庆市",
		"value": 1198,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "大兴安岭地区",
		"value": 1271,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "东营市",
		"value": 1549,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "德州市",
		"value": 1627,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "德阳市",
		"value": 2335,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "达州市",
		"value": 2419,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "大理白族自治州",
		"value": 2713,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "德宏傣族景颇族自治州",
		"value": 2726,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "迪庆藏族自治州",
		"value": 2737,
		"en": "D"
	}, {
		"sortType": "sort2",
		"text": "定西市",
		"value": 3005,
		"en": "D"
	}]
}, {
	"ch": "E",
	"ul": [{
		"sortType": "sort2",
		"text": "鄂尔多斯市",
		"value": 881,
		"en": "E"
	}, {
		"sortType": "sort2",
		"text": "鄂州市",
		"value": 1898,
		"en": "E"
	}, {
		"sortType": "sort2",
		"text": "恩施土家族苗族自治州",
		"value": 1947,
		"en": "E"
	}]
}, {
	"ch": "F",
	"ul": [{
		"sortType": "sort2",
		"text": "佛山市",
		"value": 85,
		"en": "F"
	}, {
		"sortType": "sort2",
		"text": "福州市",
		"value": 425,
		"en": "F"
	}, {
		"sortType": "sort2",
		"text": "抚顺市",
		"value": 982,
		"en": "F"
	}, {
		"sortType": "sort2",
		"text": "阜新市",
		"value": 1019,
		"en": "F"
	}, {
		"sortType": "sort2",
		"text": "阜阳市",
		"value": 1349,
		"en": "F"
	}, {
		"sortType": "sort2",
		"text": "抚州市",
		"value": 1483,
		"en": "F"
	}, {
		"sortType": "sort2",
		"text": "防城港市",
		"value": 2154,
		"en": "F"
	}]
}, {
	"ch": "G",
	"ul": [{
		"sortType": "sort2",
		"text": "广州市",
		"value": 42,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "合肥市",
		"value": 1280,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "赣州市",
		"value": 1439,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "桂林市",
		"value": 2123,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "贵港市",
		"value": 2164,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "广元市",
		"value": 2352,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "广安市",
		"value": 2413,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "甘孜藏族自治州",
		"value": 2460,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "贵阳市",
		"value": 2498,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "甘南藏族自治州",
		"value": 3032,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "果洛藏族自治州",
		"value": 3073,
		"en": "G"
	}, {
		"sortType": "sort2",
		"text": "固原市",
		"value": 3111,
		"en": "G"
	}]
}, {
	"ch": "H",
	"ul": [{
		"sortType": "sort2",
		"text": "惠州市",
		"value": 125,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "河源市",
		"value": 145,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "淮安市",
		"value": 275,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "杭州市",
		"value": 323,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "湖州市",
		"value": 369,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "邯郸市",
		"value": 567,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "衡水市",
		"value": 691,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "呼和浩特市",
		"value": 835,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "呼伦贝尔市",
		"value": 890,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "葫芦岛市",
		"value": 1056,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "哈尔滨市",
		"value": 1134,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "鹤岗市",
		"value": 1180,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "黑河市",
		"value": 1253,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "淮南市",
		"value": 1298,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "淮北市",
		"value": 1310,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "黄山市",
		"value": 1332,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "菏泽市",
		"value": 1656,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "鹤壁市",
		"value": 1728,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "黄石市",
		"value": 1858,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "黄冈市",
		"value": 1925,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "衡阳市",
		"value": 1988,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "怀化市",
		"value": 2070,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "贺州市",
		"value": 2190,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "河池市",
		"value": 2196,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "海口市",
		"value": 2224,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "红河哈尼族彝族自治州",
		"value": 2686,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "汉中市",
		"value": 2896,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "海东地区",
		"value": 3050,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "海北藏族自治州",
		"value": 3057,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "黄南藏族自治州",
		"value": 3062,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "海南藏族自治州",
		"value": 3067,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "海西蒙古族藏族自治州",
		"value": 3087,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "哈密地区",
		"value": 3140,
		"en": "H"
	}, {
		"sortType": "sort2",
		"text": "和田地区",
		"value": 3194,
		"en": "H"
	}]
}, {
	"ch": "I",
	"ul": []
}, {
	"ch": "J",
	"ul": [{
		"sortType": "sort2",
		"text": "江门市",
		"value": 91,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "揭阳市",
		"value": 172,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "嘉兴市",
		"value": 361,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "金华市",
		"value": 382,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "晋城市",
		"value": 747,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "晋中市",
		"value": 761,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "锦州市",
		"value": 1004,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "吉林市",
		"value": 1075,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "鸡西市",
		"value": 1170,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "佳木斯市",
		"value": 1226,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "景德镇市",
		"value": 1407,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "九江市",
		"value": 1418,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "吉安市",
		"value": 1458,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "济南市",
		"value": 1509,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "济宁市",
		"value": 1581,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "焦作市",
		"value": 1747,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "荆门市",
		"value": 1902,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "荆州市",
		"value": 1916,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "嘉峪关市",
		"value": 2950,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "金昌市",
		"value": 2951,
		"en": "J"
	}, {
		"sortType": "sort2",
		"text": "酒泉市",
		"value": 2988,
		"en": "J"
	}]
}, {
	"ch": "K",
	"ul": [{
		"sortType": "sort2",
		"text": "开封市",
		"value": 1680,
		"en": "K"
	}, {
		"sortType": "sort2",
		"text": "昆明市",
		"value": 2596,
		"en": "K"
	}, {
		"sortType": "sort2",
		"text": "克拉玛依市",
		"value": 3131,
		"en": "K"
	}, {
		"sortType": "sort2",
		"text": "克孜勒苏柯尔克孜自治州",
		"value": 3176,
		"en": "K"
	}, {
		"sortType": "sort2",
		"text": "喀什地区",
		"value": 3181,
		"en": "K"
	}]
}, {
	"ch": "L",
	"ul": [{
		"sortType": "sort2",
		"text": "连云港市",
		"value": 267,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "丽水市",
		"value": 414,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "龙岩市",
		"value": 501,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "廊坊市",
		"value": 680,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "临汾市",
		"value": 802,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "吕梁市",
		"value": 820,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "辽阳市",
		"value": 1027,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "辽源市",
		"value": 1092,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "六安市",
		"value": 1370,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "莱芜市",
		"value": 1611,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "临沂市",
		"value": 1614,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "聊城市",
		"value": 1639,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "洛阳市",
		"value": 1691,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "娄底市",
		"value": 2083,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "柳州市",
		"value": 2112,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "来宾市",
		"value": 2208,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "泸州市",
		"value": 2327,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "乐山市",
		"value": 2373,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "凉山彝族自治州",
		"value": 2479,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "六盘水市",
		"value": 2509,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "丽江市",
		"value": 2649,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "临沧市",
		"value": 2666,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "拉萨市",
		"value": 2742,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "林芝地区",
		"value": 2814,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "兰州市",
		"value": 2941,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "陇南市",
		"value": 3013,
		"en": "L"
	}, {
		"sortType": "sort2",
		"text": "临夏回族自治州",
		"value": 3023,
		"en": "L"
	}]
}, {
	"ch": "M",
	"ul": [{
		"sortType": "sort2",
		"text": "茂名市",
		"value": 109,
		"en": "M"
	}, {
		"sortType": "sort2",
		"text": "梅州市",
		"value": 131,
		"en": "M"
	}, {
		"sortType": "sort2",
		"text": "牡丹江市",
		"value": 1242,
		"en": "M"
	}, {
		"sortType": "sort2",
		"text": "马鞍山市",
		"value": 1305,
		"en": "M"
	}, {
		"sortType": "sort2",
		"text": "绵阳市",
		"value": 2342,
		"en": "M"
	}, {
		"sortType": "sort2",
		"text": "眉山市",
		"value": 2395,
		"en": "M"
	}]
}, {
	"ch": "N",
	"ul": [{
		"sortType": "sort2",
		"text": "南京市",
		"value": 204,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "南通市",
		"value": 258,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "宁波市",
		"value": 337,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "南平市",
		"value": 490,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "宁德市",
		"value": 509,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "南昌市",
		"value": 1397,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "南阳市",
		"value": 1786,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "南宁市",
		"value": 2099,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "内江市",
		"value": 2367,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "南充市",
		"value": 2385,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "怒江傈僳族自治州",
		"value": 2732,
		"en": "N"
	}, {
		"sortType": "sort2",
		"text": "那曲地区",
		"value": 2795,
		"en": "N"
	}]
}, {
	"ch": "O",
	"ul": []
}, {
	"ch": "P",
	"ul": [{
		"sortType": "sort2",
		"text": "莆田市",
		"value": 446,
		"en": "P"
	}, {
		"sortType": "sort2",
		"text": "盘锦市",
		"value": 1035,
		"en": "P"
	}, {
		"sortType": "sort2",
		"text": "萍乡市",
		"value": 1412,
		"en": "P"
	}, {
		"sortType": "sort2",
		"text": "平顶山市",
		"value": 1707,
		"en": "P"
	}, {
		"sortType": "sort2",
		"text": "濮阳市",
		"value": 1759,
		"en": "P"
	}, {
		"sortType": "sort2",
		"text": "攀枝花市",
		"value": 2321,
		"en": "P"
	}, {
		"sortType": "sort2",
		"text": "普洱市",
		"value": 2655,
		"en": "P"
	}, {
		"sortType": "sort2",
		"text": "平凉市",
		"value": 2980,
		"en": "P"
	}]
}, {
	"ch": "Q",
	"ul": [{
		"sortType": "sort2",
		"text": "清远市",
		"value": 157,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "衢州市",
		"value": 392,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "泉州市",
		"value": 465,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "秦皇岛市",
		"value": 559,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "齐齐哈尔市",
		"value": 1153,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "七台河市",
		"value": 1237,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "青岛市",
		"value": 1520,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "钦州市",
		"value": 2159,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "黔西南布依族苗族自治州",
		"value": 2547,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "黔东南苗族侗族自治州",
		"value": 2565,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "黔南布依族苗族自治州",
		"value": 2582,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "曲靖市",
		"value": 2611,
		"en": "Q"
	}, {
		"sortType": "sort2",
		"text": "庆阳市",
		"value": 2996,
		"en": "Q"
	}]
}, {
	"ch": "R",
	"ul": [{
		"sortType": "sort2",
		"text": "日照市",
		"value": 1606,
		"en": "R"
	}, {
		"sortType": "sort2",
		"text": "日喀则地区",
		"value": 2776,
		"en": "R"
	}]
}, {
	"ch": "S",
	"ul": [{
		"sortType": "sort2",
		"text": "上海市",
		"value": 21,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "韶关市",
		"value": 55,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "深圳市",
		"value": 66,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "汕头市",
		"value": 77,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "汕尾市",
		"value": 140,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "苏州市",
		"value": 246,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "宿迁市",
		"value": 316,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "绍兴市",
		"value": 375,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "三明市",
		"value": 452,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "石家庄市",
		"value": 520,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "朔州市",
		"value": 754,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "沈阳市",
		"value": 949,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "四平市",
		"value": 1085,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "松原市",
		"value": 1112,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "双鸭山市",
		"value": 1189,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "绥化市",
		"value": 1260,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "宿州市",
		"value": 1358,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "上饶市",
		"value": 1495,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "三门峡市",
		"value": 1779,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "商丘市",
		"value": 1800,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "十堰市",
		"value": 1865,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "随州市",
		"value": 1943,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "省直辖县级行政区划",
		"value": 1956,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "邵阳市",
		"value": 2001,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "三亚市",
		"value": 2229,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "省直辖县级行政区划",
		"value": 2230,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "遂宁市",
		"value": 2361,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "山南地区",
		"value": 2763,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "商洛市",
		"value": 2932,
		"en": "S"
	}, {
		"sortType": "sort2",
		"text": "石嘴山市",
		"value": 3101,
		"en": "S"
	}]
}, {
	"ch": "T",
	"ul": [{
		"sortType": "sort2",
		"text": "天津市",
		"value": 185,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "泰州市",
		"value": 309,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "台州市",
		"value": 404,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "唐山市",
		"value": 544,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "太原市",
		"value": 704,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "通辽市",
		"value": 872,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "铁岭市",
		"value": 1040,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "通化市",
		"value": 1097,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "铜陵市",
		"value": 1315,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "泰安市",
		"value": 1594,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "漯河市",
		"value": 1773,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "铜仁地区",
		"value": 2536,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "铜川市",
		"value": 2837,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "天水市",
		"value": 2960,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "吐鲁番地区",
		"value": 3136,
		"en": "T"
	}, {
		"sortType": "sort2",
		"text": "塔城地区",
		"value": 3214,
		"en": "T"
	}]
}, {
	"ch": "U",
	"ul": []
}, {
	"ch": "V",
	"ul": []
}, {
	"ch": "W",
	"ul": [{
		"sortType": "sort2",
		"text": "无锡市",
		"value": 218,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "温州市",
		"value": 349,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "乌海市",
		"value": 855,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "乌兰察布市",
		"value": 912,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "芜湖市",
		"value": 1288,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "潍坊市",
		"value": 1568,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "威海市",
		"value": 1601,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "武汉市",
		"value": 1844,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "梧州市",
		"value": 2141,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "文山壮族苗族自治州",
		"value": 2700,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "渭南市",
		"value": 2870,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "武威市",
		"value": 2968,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "吴忠市",
		"value": 3105,
		"en": "W"
	}, {
		"sortType": "sort2",
		"text": "乌鲁木齐市",
		"value": 3122,
		"en": "W"
	}]
}, {
	"ch": "X",
	"ul": [{
		"sortType": "sort2",
		"text": "县",
		"value": 199,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "徐州市",
		"value": 227,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "厦门市",
		"value": 439,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "邢台市",
		"value": 587,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "忻州市",
		"value": 787,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "兴安盟",
		"value": 924,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "锡林郭勒盟",
		"value": 931,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "宣城市",
		"value": 1388,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "新余市",
		"value": 1432,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "新乡市",
		"value": 1734,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "许昌市",
		"value": 1766,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "信阳市",
		"value": 1810,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "襄樊市",
		"value": 1888,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "孝感市",
		"value": 1908,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "咸宁市",
		"value": 1936,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "湘潭市",
		"value": 1982,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "湘西土家族苗族自治州",
		"value": 2089,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "县",
		"value": 2271,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "西双版纳傣族自治州",
		"value": 2709,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "西安市",
		"value": 2823,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "咸阳市",
		"value": 2855,
		"en": "X"
	}, {
		"sortType": "sort2",
		"text": "西宁市",
		"value": 3042,
		"en": "X"
	}]
}, {
	"ch": "Y",
	"ul": [{
		"sortType": "sort2",
		"text": "阳江市",
		"value": 152,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "云浮市",
		"value": 178,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "盐城市",
		"value": 284,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "扬州市",
		"value": 294,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "阳泉市",
		"value": 727,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "运城市",
		"value": 773,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "营口市",
		"value": 1012,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "延边朝鲜族自治州",
		"value": 1124,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "伊春市",
		"value": 1208,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "鹰潭市",
		"value": 1435,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "宜春市",
		"value": 1472,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "烟台市",
		"value": 1555,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "宜昌市",
		"value": 1874,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "岳阳市",
		"value": 2014,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "益阳市",
		"value": 2039,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "永州市",
		"value": 2058,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "玉林市",
		"value": 2170,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "宜宾市",
		"value": 2402,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "雅安市",
		"value": 2427,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "玉溪市",
		"value": 2621,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "延安市",
		"value": 2882,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "榆林市",
		"value": 2908,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "玉树藏族自治州",
		"value": 3080,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "银川市",
		"value": 3094,
		"en": "Y"
	}, {
		"sortType": "sort2",
		"text": "伊犁哈萨克自治州",
		"value": 3203,
		"en": "Y"
	}]
}, {
	"ch": "Z",
	"ul": [{
		"sortType": "sort2",
		"text": "珠海市",
		"value": 73,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "湛江市",
		"value": 99,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "肇庆市",
		"value": 116,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "中山市",
		"value": 167,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "镇江市",
		"value": 302,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "舟山市",
		"value": 399,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "漳州市",
		"value": 478,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "张家口市",
		"value": 633,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "朝阳市",
		"value": 1048,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "淄博市",
		"value": 1533,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "枣庄市",
		"value": 1542,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "郑州市",
		"value": 1667,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "周口市",
		"value": 1821,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "驻马店市",
		"value": 1832,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "株洲市",
		"value": 1972,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "张家界市",
		"value": 2034,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "自贡市",
		"value": 2314,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "资阳市",
		"value": 2441,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "遵义市",
		"value": 2514,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "昭通市",
		"value": 2637,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "张掖市",
		"value": 2973,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "中卫市",
		"value": 3117,
		"en": "Z"
	}, {
		"sortType": "sort2",
		"text": "自治区直辖县级行政区划",
		"value": 3230,
		"en": "Z"
	}]
}]
