import { http } from "./index.js"
//热门城市 
export const hot = (option) => {
    return http({
        url: "/api/userapp.regions/hotcity",
        method: "get",
        params: option
    })
}

//登录 /api/user/login
export const login = (option) => {
    return http({
        url: "/api/user/login",
        method: "get",
        params: option
    })
}

//手机验证码登录 /api/user/mobilelogin
export const mobilelogin = (option) => {
    return http({
        url: "/api/user/mobilelogin",
        method: "get",
        params: option
    })
}

//发送验证码  /api/sms/send
export const sendcode = (option) => {
    return http({
        url: "/api/sms/send",
        method: "get",
        params: option
    })
}

//科普标签列表  /api/userapp.science/cate  /api/userapp.science/triallabel
export const cate = (option) => {
    return http({
        url: "/api/userapp.science/triallabel",
        method: "get",
        params: option
    })
}

//科普列表  /api/userapp.science/listcontent
export const listcontent = (option) => {
    return http({
        url: "/api/userapp.science/listcontent",
        method: "get",
        params: option
    })
}

//发布科普关联项目 /api/userapp.science/relatedtrial
export const relatedtrial = (option) => {
    return http({
        url: "/api/userapp.science/relatedtrial",
        method: "get",
        params: option
    })
}

//发布科普 /api/userapp.science/addcontent
export const addcontent = (option) => {
    return http({
        url: "/api/userapp.science/addcontent",
        method: "post",
        data: option
    })
}

//医生等级  /api/userapp.doctors/positionlist
export const level = (option) => {
    return http({
        url: "/api/userapp.doctors/positionlist",
        method: "get",
        params: option
    })
}

//科室列表 /api/userapp.doctors/collections
export const collections = (option) => {
    return http({
        url: "/api/userapp.doctors/collections",
        method: "get",
        params: option
    })
}

//医生列表  /api/userapp.doctors/colldoctors
export const doctors = (option) => {
    return http({
        url: '/api/userapp.doctors/colldoctors',
        method: "get",
        params: option
    })
}

//医生详情中心 /api/userapp.doctors/index
export const doctorinfo = (option) => {
    return http({
        url: "/api/userapp.doctors/index",
        method: "get",
        params: option
    })
}

//医生科普列表 /api/userapp.doctors/listcontent
export const doctor_listcontent = (option) => {
    return http({
        url: "/api/userapp.doctors/listcontent",
        method: "get",
        params: option
    })
}

//医生案例列表 /api/userapp.doctors/casestudylist
export const casestudylist = (option) => {
    return http({
        url: "/api/userapp.doctors/casestudylist",
        method: "get",
        params: option
    })
}

//医生服务项目列表 /api/userapp.doctors/dgoodslist
export const dgoodslist = (option) => {
    return http({
        url: "/api/userapp.doctors/dgoodslist",
        method: "get",
        params: option
    })
}

//社区详情 /api/userapp.science/detail
export const detail = (option) => {
    return http({
        url: "/api/userapp.science/detail",
        method: "get",
        params: option
    })
}

//科普评论 /api/userapp.science/addcomment
export const addcomment = (option) => {
    return http({
        url: "/api/userapp.science/addcomment",
        method: "post",
        data: option
    })
}

//科普评论列表 /api/userapp.science/comment
export const comment = (option) => {
    return http({
        url: "/api/userapp.science/comment",
        method: "get",
        params: option
    })
}

//科普点赞 /api/userapp.science/like
export const like = (option) => {
    return http({
        url: "/api/userapp.science/like",
        method: "post",
        data: option
    })
}

//收藏 /api/userapp.science/setfavorite
export const setfavorite = (option) => {
    return http({
        url: "/api/userapp.science/setfavorite",
        method: "post",
        data: option
    })
}

//评论点赞 /api/userapp.science/commentlike
export const commentlike = (option) => {
    return http({
        url: "/api/userapp.science/commentlike",
        method: "post",
        data: option
    })
}

//注册 /api/user/newregister
export const register = (option) => {
    return http({
        url: "/api/user/newregister",
        method: "get",
        params: option
    })
}

//热卖专区 /api/lyz.b2c.goods/hotlist
export const hotlist = (option) => {
    return http({
        url: "/api/lyz.b2c.goods/hotlist",
        method: "get",
        params: option
    })
}

//关注列表 /api/userapp.other/attention
export const attention = (option) => {
    return http({
        url: "/api/userapp.other/attention",
        method: "get",
        params: option
    })
}

//粉丝列表 /api/userapp.other/fans
export const fans = (option) => {
    return http({
        url: "/api/userapp.other/fans",
        method: "get",
        params: option
    })
}

//发布的科普列表 /api/userapp.other/contentlist
export const mylist = (option) => {
    return http({
        url: "/api/userapp.other/contentlist",
        method: 'get',
        params: option
    })
}

//用户信息  /api/userapp.other/info
export const otherinfo = (option) => {
    return http({
        url: "/api/userapp.other/info",
        method: "get",
        params: option
    })
}

//删除笔记 /api/userapp.science/del
export const del_article = (option) => {
    return http({
        url: "/api/userapp.science/del",
        method: "post",
        data: option
    })
}

//关注/取消关注 /api/user/setattention
export const setattention = (option) => {
    return http({
        url: "/api/user/setattention",
        method: "post",
        data: option
    })
}

//商城分类列表 /api/lyz.b2c.category/catelist
export const shop_catelist = (option) => {
    return http({
        url: "/api/lyz.b2c.category/catelist",
        method: 'get',
        params: option
    })
}

//集合列表 /api/lyz.b2c.collections/colllist
export const colllist = (option) => {
    return http({
        url: "/api/lyz.b2c.collections/colllist",
        method: "get",
        params: option
    })
}

//集合商品列表 /api/lyz.b2c.collections/collgoodslist
export const collgoodslist = (option) => {
    return http({
        url: "/api/lyz.b2c.collections/collgoodslist",
        method: "get",
        params: option
    })
}

//商城商品列表 /api/lyz.b2c.goods/index
export const shop_goodslist = (option) => {
    return http({
        url: "/api/lyz.b2c.goods/index",
        method: "get",
        params: option
    })
}

//商品详情 /api/lyz.b2c.product/detail
export const shop_goodsdetail = (option) => {
    return http({
        url: "/api/lyz.b2c.product/detail",
        method: "get",
        params: option
    })
}

//商品查医生 /api/userapp.doctors/goods_doctors
export const goods_doctors = (option) => {
    return http({
        url: "/api/userapp.doctors/goods_doctors",
        method: "get",
        params: option
    })
}

//商品评论 /api/lyz.b2c.product/commnets
export const goods_comments = (option) => {
    return http({
        url: "/api/lyz.b2c.product/commnets",
        method: "get",
        params: option
    })
}

//医院列表 /api/userapp.hospital/hospitallist
export const hospitallist = (option) => {
    return http({
        url: "/api/userapp.hospital/hospitallist",
        method: "get",
        params: option
    })
}

//预约表 /api/userapp.doctors/appointmentlist
export const appointmentlist = (option) => {
    return http({
        url: "/api/userapp.doctors/appointmentlist",
        method: "get",
        params: option
    })
}

//商品购买 /api/lyz.b2c.cart/fastbuy
export const fastbuy = (option) => {
    return http({
        url: "/api/lyz.b2c.cart/fastbuy",
        method: "post",
        data: option
    })
}

//订单确认展示 /api/lyz.b2c.checkout/index
export const checkout = (option) => {
    return http({
        url: "/api/lyz.b2c.checkout/index",
        method: 'get',
        params: option
    })
}

//快速购买订单确认 /api/lyz.b2c.checkout/fastbuy
export const confrim_buy = (option) => {
    return http({
        url: "/api/lyz.b2c.checkout/fastbuy",
        method: 'get',
        params: option
    })
}

//创建订单 /api/lyz.b2c.orders/create
export const createorder = (option) => {
    return http({
        url: "/api/lyz.b2c.orders/create",
        method: 'get',
        params: option
    })
}

//发起支付 /api/lyz.b2c.checkout/dopayment
export const dopayment = (option) => {
    return http({
        url: "/api/lyz.b2c.checkout/dopayment",
        method: 'get',
        params: option
    })
}

//订单状态 订单详情 /api/lyz.b2c.orders/detail
export const order_detail = (option) => {
    return http({
        url: "/api/lyz.b2c.orders/detail",
        method: 'get',
        params: option
    })
}

//医生案例详情 /api/userapp.doctors/casestudyinfo
export const casestudyinfo = (option) => {
    return http({
        url: "/api/userapp.doctors/casestudyinfo",
        method: "get",
        params: option
    })
}

//我的订单 /api/lyz.b2c.user/orders
export const myorders = (option) => {
    return http({
        url: "/api/lyz.b2c.user/orders",
        method: "get",
        params: option
    })
}

//订单评价 /api/lyz.b2c.orders/comment
export const order_comment = (option) => {
    return http({
        url: "/api/lyz.b2c.orders/comment",
        method: "post",
        data: option
    })
}

//首页搜索 /api/lyz.b2c.goods/search
export const home_search = (option) => {
    return http({
        url: "/api/lyz.b2c.goods/search",
        method: "get",
        params: option
    })
}

//搜索记录 /api/userapp.historysearch/index
export const historysearch = (option) => {
    return http({
        url: "/api/userapp.historysearch/index",
        method: "get",
        params: option
    })
}

//保存搜索记录 /api/userapp.historysearch/savesearch
export const savesearch = (option) => {
    return http({
        url: "/api/userapp.historysearch/savesearch",
        method: "post",
        data: option
    })
}

//热门搜索 /api/userapp.historysearch/hotsearch
export const hotsearch = (option) => {
    return http({
        url: "/api/userapp.historysearch/hotsearch",
        method: "get",
        params: option
    })
}

//加盟合作 提交申请 /api/web.joinmessage/addmessage
export const addmessage = (option) => {
    return http({
        url: "/api/web.joinmessage/addmessage",
        method: "get",
        params: option
    })
}

//门店列表 /api/web.store/getstorelist
export const getstorelist = (option) => {
    return http({
        url: "/api/web.store/getstorelist",
        method: "get",
        params: option
    })
}

//课程列表 /api/web.course/getcourselist
export const getcourselist = (option) => {
    return http({
        url: "/api/web.course/getcourselist",
        method: "get",
        params: option
    })
}

//banner /api/web.banner/getbannerlist
export const getbannerlist = (option) => {
    return http({
        url: "/api/web.banner/getbannerlist",
        method: "get",
        params: option
    })
}
export default {
    hot,
    login,
    mobilelogin,
    sendcode,
    cate,
    listcontent,
    level,
    collections,
    doctors,
    doctorinfo,
    doctor_listcontent,
    casestudylist,
    dgoodslist,
    detail,
    addcomment,
    comment,
    like,
    setfavorite,
    commentlike,
    register,
    hotlist,
    attention,
    fans,
    mylist,
    otherinfo,
    del_article,
    setattention,
    shop_catelist,
    colllist,
    collgoodslist,
    shop_goodslist,
    shop_goodsdetail,
    goods_doctors,
    hospitallist,
    appointmentlist,
    fastbuy,
    checkout,
    createorder,
    dopayment,
    relatedtrial,
    addcontent,
    confrim_buy,
    order_detail,
    goods_comments,
    casestudyinfo,
    myorders,
    order_comment,
    home_search,
    historysearch,
    savesearch,
    hotsearch,
    addmessage,
    getstorelist,
    getcourselist,
    getbannerlist
}