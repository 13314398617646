<template>
  <div class="empty">
    <div>
      <img src="../static/my/empty-image-default.png" />
      <div style="text-align: center">暂无数据</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    width: {
      type: String,
      default: "0",
    },
    height: {
      type: String,
      default: "600",
    },
  },
  computed: {
    empty() {
      let style = {};
      style.width = this.width == "0" ? "100%" : this.width + "px";
      style.height = this.height + "px";
      return style;
    },
  },
};
</script>
<style scoped>
.empty {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
