<template>
  <div class="step-form">
    <input type="file" style="display: none" ref="file1" @change="getFileData" />
    <input type="file" style="display: none" ref="file2" @change="getFileData2" />
    <div class="step-item">
      <div class="item-title">1.请拍照并上传你的身份证照片</div>
      <div class="ident-area">
        <div class="ident-text">身份证正面</div>
        <img :src="imgsrc1" class="ident-img" @click="upload1" />
      </div>
      <div class="ident-area">
        <div class="ident-text">身份证反面</div>
        <img :src="imgsrc2" class="ident-img" @click="upload2" />
      </div>
    </div>
    <div class="step-item">
      <div class="item-title">2.拍摄身份证要求</div>
      <div class="ident-text">大陆公民持有的本人有效二代身份证；</div>
      <div class="ident-text">
        拍摄时确保身份证：<strong style="color: #333"
          >边框完整，字体清晰，亮度均匀。</strong
        >
      </div>
      <div class="ident-area2">
        <div class="txt-center">
          <img
            class="ident-item"
            src="../../static/join/certification_img_correct@2x.png"
          />
          <b-icon icon="check-circle-fill" class="check-icon"></b-icon>
        </div>
        <div class="txt-center">
          <img
            class="ident-item"
            src="../../static/join/certification_img_error1@2x.png"
          />
          <b-icon icon="x-circle-fill" class="error-icon"></b-icon>
        </div>
        <div class="txt-center">
          <img
            class="ident-item"
            src="../../static/join/certification_img_error2@2x.png"
          />
          <b-icon icon="x-circle-fill" class="error-icon"></b-icon>
        </div>
        <div class="txt-center">
          <img
            class="ident-item"
            src="../../static/join/certification_img_error3@2x.png"
          />
          <b-icon icon="x-circle-fill" class="error-icon"></b-icon>
        </div>
      </div>
    </div>
    <div class="form-submit">
      <div class="form-btn" @click="back" style="margin-right: 20px">上一步</div>
      <div class="form-btn" @click="next">下一步</div>
    </div>
  </div>
</template>
<script>
import { uploader } from "../../common/ossUploadfile.js";
export default {
  data() {
    return {
      imgsrc1: require("../../static/join/zhengmian.png"),
      imgsrc2: require("../../static/join/fanmian.png"),
      detail: {
        ident_p: "",
        ident_n: "",
      },
    };
  },
  methods: {
    upload1() {
      this.$refs.file1.dispatchEvent(new MouseEvent("click"));
    },
    upload2() {
      this.$refs.file2.dispatchEvent(new MouseEvent("click"));
    },
    getFileData() {
      let inputFile = this.$refs.file1.files[0];
      uploader(inputFile).then((res) => {
        this.imgsrc1 = res.ImgUrl;
        this.detail.ident_p = res.url;
      });
    },
    getFileData2() {
      let inputFile = this.$refs.file2.files[0];
      uploader(inputFile).then((res) => {
        this.imgsrc2 = res.ImgUrl;
        this.detail.ident_n = res.url;
      });
    },
    next() {
      if (this.detail.ident_p == "" || this.detail.ident_n == "") {
        return this.$store.commit("showToast", "请上传完整的身份证信息");
      }
      this.$emit("next", this.detail);
    },
    back() {
      this.$emit("back")
    },
  },
};
</script>
<style scope lang="scss">
.step-form {
  padding: 0 10px;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.step-item {
  margin: 15px 0;
  width: 850px;
  display: flex;
  flex-wrap: wrap;
  .item-title {
    font-size: 14px;
    color: #666;
    width: 850px;
    height: 32px;
    line-height: 32px;
  }
  .ident-text {
    color: #999;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
  }
  .ident-area {
    width: 400px;

    .ident-img {
      width: 260px;
      height: 171px;
      margin: 10px 0;
    }
    .ident-img:hover {
      cursor: pointer;
    }
  }
  .ident-area2 {
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin: 10px 0;
    .ident-item {
      width: 161px;
      height: 96px;
      border-radius: 4px;
    }
    .sure-bg {
      width: 21px;
      margin-left: 70px;
      margin-top: 5px;
      text-align: center;
      height: 21px;
      border-radius: 50%;
      z-index: 999;
      line-height: 21px;
      color: #fff;
    }
    .txt-center {
      text-align: center;
      width: 161px;
    }
    .error-icon {
      color: red;
      font-size: 21px;
      margin-top: 5px;
    }
    .check-icon {
      color: #3289ed;
      font-size: 21px;
      margin-top: 5px;
    }
  }
  .form-submit {
    width: 850px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    .form-btn {
      background: #fd9852;
      color: #fff;
      text-align: center;
      width: 131px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
    }
    .form-btn:hover {
      cursor: pointer;
    }
  }
}
</style>
