export const setCache = (key, value) => {
    if (key && value) {
        return localStorage.setItem(key, value)
    }
}

export const getCache = (key) => {
    if (key) return localStorage.getItem(key)
}

export const removeCache = (key) => {
    if (key) return localStorage.removeItem(key)
}

export default {
    setCache,
    getCache,
    removeCache
}