<template>
  <div class="header">
    <div class="top">
      <div style="width: 1200px; display: flex">
        <div class="select">
          <div v-if="!userinfo">
            <router-link style="text-decoration: none; color: #666" to="/login"
              >登录
            </router-link>
            |
            <router-link style="text-decoration: none; color: #666" to="/login/register"
              >注册</router-link
            >
          </div>
          <div class="sss" v-else id="info" @click="infoPop = !infoPop">
            <img
              class="avatar"
              :src="userinfo.touxiang"
              @error="userinfo.touxiang = require('../static/my/default_avatar.png')"
            />
            {{ userinfo.nickname }}
          </div>
          <b-popover
            target="info"
            :show="infoPop"
            placement="bottom"
            custom-class="info-pop"
          >
            <div class="info-pop-title" @click="toPage('/my_info')">个人主页</div>
            <div class="info-pop-title" @click="toPage('/order')">我的订单</div>
            <div class="info-pop-title">我的消息</div>
            <div class="info-pop-title" @click="logout">退出登录</div>
          </b-popover>
        </div>
        <b-link class="select" @click="toPage('/doctorInfo')">医生入驻</b-link>
        <b-link class="select" id="download">下载App</b-link>
        <!-- <b-link class="select" @click="changeTabs(3, 'i')">招商合作</b-link> -->
        <b-link class="select" @click="toJoin">招商合作</b-link>
      </div>
      <b-popover target="download" triggers="hover" placement="bottom">
        <div>
          <img src="../static/images/down.png" style="width: 120px; height: auto" />
          <div style="" class="download-custem">iPhone | Android</div>
          <div style="width: 120px; text-align: center; height: 32px; line-height: 32px">
            扫码下载app
          </div>
        </div>
      </b-popover>
    </div>
    <div class="banner">
      <div class="left">
        <img class="logo" src="../static/images/mf.png" @click="toHome" />
        <div class="logo-div">
          <div class="logo-title">美蜂医美App</div>
          <div class="logo-desc">想变美，上美蜂，找名医</div>
        </div>
        <b-link class="select-city" id="popover-target-1" @click="show = !show"
          >{{ cityLabel }}
          <b-icon
            :icon="show ? 'caret-up-fill' : 'caret-down-fill'"
            style="color: #fd9852"
          ></b-icon>
        </b-link>
      </div>
      <b-popover
        target="popover-target-1"
        placement="bottom"
        custom-class="popover-area"
        :show.sync="show"
      >
        <!-- triggers="click" -->
        <template #title>
          <b-input
            v-model="cityName"
            placeholder="请输入城市名"
            @change="inputCityName"
          ></b-input>
        </template>
        <city @selectcity="selectcity"></city>
      </b-popover>
      <div class="center">
        <b-input-group class="mt-3">
          <template #append>
            <b-button class="btn" variant="primary" @click="saveSearch">
              <b-icon icon="search" style="color: #fff"></b-icon>
            </b-button>
          </template>
          <b-form-input
            class="input"
            placeholder="输入关键词"
            maxlength="7"
            list="my-list"
            v-model="search"
          ></b-form-input>
          <datalist id="my-list">
            <option v-for="(size, i) in history" :key="i">{{ size.content }}</option>
          </datalist>
        </b-input-group>
        <div class="options">
          <b-link class="option" style="color: #fd9852">大家都在看：</b-link>
          <div
            class="option"
            v-for="(hot, i) in hot.slice(0, 5)"
            :key="i"
            @click="see(hot.content, hot.product_id)"
          >
            {{ hot.content }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="serve-icon">
          <b-icon icon="telephone"></b-icon>
        </div>
        <div style="width: 200px">
          <div class="font-hot">客服热线</div>
          <div style="font-hot-num">400-851-5180</div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div style="width: 1200px; display: flex">
        <div
          class="nav-item-li"
          v-for="(item, i) in navs"
          :key="i"
          :class="active == i ? 'active' : ''"
          @click="changeTabs(i)"
        >
          <a href="javascript:;" class="nav-item-link">{{ item.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import city from "./city.vue";
export default {
  components: {
    city,
  },
  computed: {
    userinfo() {
      let user = JSON.parse(this.$cache.getCache("userInfo"));
      user.touxiang = this.$host + user.avatar;
      return user;
    },
  },
  data() {
    return {
      navs: [
        { name: "首页", path: "/" },
        { name: "社区", path: "/community" },
        { name: "商城", path: "/shop" },
        { name: "关于我们", path: "/about" },
        { name: "新闻中心", path: "/news" },
      ],
      active: 0,
      cityName: "",
      cityLabel: "选择城市",
      show: false,
      infoPop: false,
      search: "",
      history: [],
      hot: [],
    };
  },
  mounted() {
    if (!this.$cache.getCache("userInfo") || this.$cache.getCache("userInfo") == "") {
      return this.$router.push({
        path: "/login",
      });
    } else {
      this.getHistory();
      this.getHotSearch();
    }
  },
  methods: {
    getHotSearch() {
      this.$http.hotsearch({}).then((res) => {
        let { code, data } = res;
        if (code == 1) {
          this.hot = data;
        }
      });
    },
    toRouter(path) {
      this.$router.push({
        path: path,
      });
    },
    changeTabs(index, i) {
      if (i) {
        this.$EventBus.$emit("scrollTo");
      }
      this.active = index;
      this.$router.push({
        path: this.navs[index].path,
      });
    },
    selectIndex(item) {
      this.indexActive = item;
    },
    inputCityName() {
      this.show = false;
      this.cityLabel = this.cityName ? this.cityName : "选择城市";
    },
    selectcity(option) {
      console.log(option);
      this.cityLabel = option.name;
      this.show = false;
    },
    toPage(url) {
      this.infoPop = false;
      this.$router.push({
        path: url,
      });
    },
    logout() {
      this.infoPop = false;
      this.$cache.removeCache("userInfo");
      this.$store.commit("showToast", "退出登录成功");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
        });
      }, 2000);
    },
    getHistory() {
      this.$http.historysearch({}).then((res) => {
        let { code, data } = res;
        if (code == 1) {
          this.history = data;
        }
      });
    },
    saveSearch() {
      let data = { content: this.search };
      this.$http.savesearch(data).then((res) => {
        if (res.code == 1) {
          this.getHistory();
        }
      }); 
      setTimeout(() => {
        this.$router.push({
          path: "/searchview",
        });
      });
      this.$EventBus.$emit("searchEvent", this.search);
    },
    see(e, id) {
      this.search = e;
      this.$router.push({
        path: "/goods_info?id=" + id,
      });
      // this.$EventBus.$emit("searchEvent", e);
    },
    toHome() {
      this.$router.push({
        path: "/doctor",
      });
    },
    toJoin() {
      this.$router.push({
        path: "/become"
      })
    }
  },
};
</script>
<style scoped lang="scss">
@import "./header.scss";
.input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  // height: 40px;
  // line-height: 40px;
  width: 320px;
}
.popover-area {
  min-width: 600px !important;
}
.download-custem {
  width: 120px;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  margin-top: 20px;
}
.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}
.info-pop {
  width: 150px;
  height: 150px;
  .info-pop-title {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
  }
  .info-pop-title:hover {
    cursor: pointer;
  }
}
.sss:hover {
  cursor: pointer;
}
.font-hot {
  font-size: 13px;
  height: 20px;
  line-height: 20px;
}
.font-hot-num {
  font-size: 20px;
  height: 30px;
  line-height: 30px;
}
</style>
