<template>
  <div class="page">
    <div class="step">
      <div class="step-item" @click="changeCurrent(0)">
        <div class="step-icon-default" :class="current >= 0 ? 'step-active' : ''">
          <b-icon v-show="current > 0" size="14" icon="check"></b-icon>
        </div>
        <div>基础信息</div>
      </div>

      <div class="step-line"></div>
      <div class="step-item" @click="changeCurrent(1)">
        <div class="step-icon-default" :class="current > 0 ? 'step-active' : ''">
          <b-icon v-show="current > 1" size="14" icon="check"></b-icon>
        </div>
        <div>实名认证</div>
      </div>

      <div class="step-line"></div>
      <div class="step-item" @click="changeCurrent(2)">
        <div class="step-icon-default" :class="current > 1 ? 'step-active' : ''">
          <b-icon v-show="current > 2" size="14" icon="check"></b-icon>
        </div>
        <div>资质证件</div>
      </div>
    </div>
    <div>
      <step-1 v-show="current == 0" @next="nextStep1"></step-1>
      <step-2 v-show="current == 1" @next="nextStep2" @back="back"></step-2>
      <step-3 v-show="current == 2" @finish="nextStep3" @back="back"></step-3>
    </div>
  </div>
</template>
<script>
import Step1 from "../components/join/step1.vue";
import Step2 from "../components/join/step2.vue";
import Step3 from "../components/join/step3.vue";
export default {
  data() {
    return {
      current: 0,
      value: "",
      imgsrc: "",
      data1: {},
      data2: {},
      data3: {},
      
    };
  },
  components: {
    Step1,
    Step2,
    Step3,
  },
  methods: {
    nextStep1(ctx) {
      
      this.data1 = ctx
      this.current = 1;
    },
    nextStep2(ctx) {
      
      this.data2 = ctx
      this.current = 2;
    },
    nextStep3(ctx) {
      this.data3= ctx
      this.submit()
    },
    submit() {
      let param = this.data1
      this.data3.ident_p = this.data2.ident_p
      this.data3.ident_n = this.data2.ident_n
      param.working = JSON.stringify(this.data3)
      console.log(param)
      this.$request.certification(param).then(res => {
        let {code, msg} = res
        if(code == 1) {
          this.$store.commit("showToast", msg)
          setTimeout(() => {
            this.$router.push({
              path: "/doctorInfo"
            })
          }, 1500)
        }else {
          this.$store.commit("showToast", msg)
        }
      })
    },
    changeCurrent() {
      
    },
    back() {
      this.current -- 
    }
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 910px;
  padding: 30px 20px;
  min-height: 620px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  .step {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .step-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin: 0 10px;
    }
    .step-icon-default {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin: 0 10px;
      border: 1px solid #999;
      color: #fff;
      text-align: center;
      // background: #fd9852;
    }
    .step-active {
      background: #fd9852;
      border: 1px solid #fff;
    }
    .step-line {
      height: 2px;
      width: 320px;
      border: 1px solid #ccc;
    }
    .step-item {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
