import Vue from "vue"
import Router from "vue-router"
import Home from "./components/Home.vue"
import Login from "./components/Login.vue"
import Join from "./components/Join.vue"
import DoctorLogin from "./components/DoctorLogin.vue"
Vue.use(Router)

export default new Router({
    // mode: 'history',
    mode: "hash",
    base: "/",
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    routes: [{
            path: "/",
            name: "Home",
            component: Home,
            redirect: "/doctor",
            children: [{
                    path: "/home",
                    component: () =>
                        import ("@/components/pages/Home.vue")
                },
                {
                    path: "/community",
                    component: () =>
                        import ("@/components/pages/Community.vue")
                },
                {
                    path: "/doctor",
                    component: () =>
                        import ("@/components/pages/Doctor.vue")
                },
                {
                    path: "/shop",
                    component: () =>
                        import ("@/components/pages/Shop.vue")
                },
                {
                    path: "/news",
                    component: () =>
                        import ("@/components/pages/News.vue")
                },
                {
                    path: "/about",
                    component: () =>
                        import ("@/components/pages/About.vue")
                },
                //医生详情
                {
                    path: "/doctor_info",
                    component: () =>
                        import ("@/components/doctors/info.vue")
                },
                //商品详情
                {
                    path: "/goods_info",
                    component: () =>
                        import ("@/components/shop/goods.vue")
                },
                //发布文章
                {
                    path: "/edit_article",
                    component: () =>
                        import ("@/components/community/editArticle.vue")
                },
                //社区详情
                {
                    path: "article",
                    component: () =>
                        import ("@/components/community/article.vue")
                },
                //新闻中心
                {
                    path: "/news_info1",
                    component: () =>
                        import ("@/components/news/info1.vue")
                },
                //个人中心
                {
                    path: "/my_info",
                    component: () =>
                        import ("@/components/my/info.vue")
                },
                //商品购买
                {
                    path: "/setorder",
                    component: () =>
                        import ("@/components/shop/setorder.vue")
                },
                //案例详情
                {
                    path: "/doctor_serve",
                    component: () =>
                        import ("@/components/doctors/serve.vue")
                },
                //订单
                {
                    path: "/order",
                    component: () =>
                        import ("@/components/order/index.vue")
                },
                //评价订单
                {
                    path: "/order_comment",
                    component: () =>
                        import ("@/components/order/comment.vue")
                },
                //订单详情
                {
                    path: "/order_info",
                    component: () =>
                        import ("@/components/order/orderinfo.vue")
                },
                //搜索结果
                {
                    path: "/searchview",
                    component: () =>
                        import ("@/components/pages/SearchView.vue")
                }
            ]
        },
        {
            path: "/login",
            component: Login,
            // redirect: "/login/index",
            children: [{
                    path: "/login",
                    name: "Login",
                    component: () =>
                        import ("@/components/login/LoginIndex.vue")
                },
                {
                    path: "/login/register",
                    name: "Register",
                    component: () =>
                        import ("@/components/login/LoginRegister.vue")
                },
            ]
        },
        {
            path: "/become",
            component: () =>
                import ("@/components/become/index.vue"),
            redirect: "/become/index",
            children: [{
                    path: "/become/index",
                    component: () =>
                        import ("@/components/become/info.vue")
                },
                {
                    path: "/become/flow",
                    component: () =>
                        import ("@/components/become/flow.vue")
                },
                {
                    path: "/become/mall",
                    component: () =>
                        import ("@/components/become/mall.vue")
                },
                {
                    path: "/become/training",
                    component: () =>
                        import ("@/components/become/training.vue")
                },
                {
                    path: "/become/traininginfo",
                    name: "traininginfo",
                    component: () =>
                        import ("@/components/become/trainingInfo.vue")
                },
            ]
        },
        {
            path: "/doctorInfo",
            component: () =>
                import ("@/components/DoctorInfo.vue"),
            children: [{
                    path: "/doctorInfo/join",
                    component: Join
                }, {
                    path: "/doctorInfo",
                    component: () =>
                        import ("@/components/doctorinfo/index.vue")
                }, {
                    path: "/doctorInfo/hospital",
                    component: () =>
                        import ("@/components/doctorinfo/hospital.vue")
                },
                {
                    path: "/doctorInfo/hospitalinfo",
                    component: () =>
                        import ("@/components/doctorinfo/hosp/info.vue")
                },
                {
                    path: "/doctorInfo/community",
                    component: () =>
                        import ("@/components/doctorinfo/home/index.vue")
                },
                {
                    path: "/doctorInfo/order",
                    component: () =>
                        import ("@/components/doctorinfo/order/index.vue")
                },
                {
                    path: "/doctorInfo/message",
                    component: () =>
                        import ("@/components/doctorinfo/message/index.vue")
                },
                {
                    path: "/doctorInfo/underline",
                    component: () =>
                        import ("@/components/doctorinfo/underline/index.vue")
                }
            ]
        },
        {
            path: "/doctorLogin",
            component: DoctorLogin,
            children: [{
                    path: "/doctorLogin",
                    name: "DoctorLogin",
                    component: () =>
                        import ("@/components/join/login.vue")
                },
                {
                    path: "/doctorLogin/register",
                    name: "DoctorRegister",
                    component: () =>
                        import ("@/components/join/register.vue")
                },
            ]
        }
    ]
})