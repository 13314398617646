<template>
  <div class="main">
    <router-view />
    <Toast class="new-toast" :title="title" v-show="show"></Toast>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    show: function () {
      return this.$store.state.showToast;
    },
    title: function() {
      return this.$store.state.showTitle;
    }
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit("hideToast");
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss">
.main {
  width: 100%;
  margin: 0;
}
.new-toast {
  width: 240px;
  position: fixed;
  top: 50%;
  z-index: 999;
  left: 50%;
  margin-left: -120px;
  margin-top: -30px;
  text-align: center;
  height: 60px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  color: #fff;
  background: #000;
  border-radius: 0.25rem;
}
</style>
