<template>
  <div class="app">
    <Header></Header>
    <div class="page">
      <router-view></router-view>
    </div>
    <div class="right-menu">
      <div class="item" @click="urlPage('/edit_article')">
        <div class="item-content">
          <div class="item-top">
            <b-icon icon="pencil-square"></b-icon>
          </div>
          <div class="item-botm">发布内容</div>
        </div>
      </div>
      <!-- <div class="item" @click="urlPage('/my_info')">
        <div class="item-content">
          <div class="item-top">
            <b-icon icon="person-fill"></b-icon>
          </div>
          <div class="item-botm">我的主页</div>
        </div>
      </div> -->
      <div class="item">
        <div class="item-content">
          <div class="item-top">
            <b-icon icon="headset"></b-icon>
          </div>
          <div class="item-botm">在线客服</div>
        </div>
      </div>
      <div class="item">
        <div class="item-content">
          <div class="item-top">
            <b-icon icon="chat-square-dots"></b-icon>
          </div>
          <div class="item-botm">消息</div>
        </div>
      </div>
      <div class="item" @click="urlPage('/order')">
        <div class="item-content">
          <div class="item-top">
            <b-icon icon="card-list"></b-icon>
          </div>
          <div class="item-botm">我的订单</div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from "../utils/Header.vue";
import Bottom from "../utils/Bottom.vue";
export default {
  name: "Home",
  components: {
    Header,
    Bottom,
  },
  methods: {
    urlPage(path) {
      this.$router.push({
        path: path
      })
    }
  }
};
</script>

<style scoped lang="scss">
.app {
  width: 100%;
  background: #fff;
}
.page {
  width: 100%;
}
.right-menu {
  position: fixed;
  right: 0;
  top: 30vh;
  width: 120px;
  height: 50vh;
  .item {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-content {
    width: 80px;
    height: 80px;
    border: 1px solid #e6e7e9;
    font-size: 14px;
    text-align: center;
    color: #333;
    border-radius: 4px;
    background: #fff;
  }
  .item-content:hover {
    cursor: pointer;
  }
  .item-icon {
    width: 48px;
    height: 48px;
    margin-top: 4px;
  }
  .item-top {
    height: 56px;
    text-align: center;
    font-size: 28px;
    line-height: 56px;
    color: #fd9852;
    font-weight: bold;
  }
  .item-botm {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
  }
}
</style>
