import Crypto from './utils/crypto1/crypto/crypto.js';
import './utils/crypto1/hmac/hmac.js';
import './utils/crypto1/sha1/sha1.js';
import {
    Base64
} from './utils/base64.js';
import axios from "axios"
// var uploadFileSize = 1024 * 1024 * 100; // 上传文件的大小限制100m
var policyText = {
    "expiration": "2030-01-01T12:00:00.000Z", //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
    "conditions": [{
            "bucket": "meifeier"
                // "bucket": "zjmeifeng"
        },
        ["starts-with", "$key", "uniapp_c/"]
    ],
};

// // 美蜂测试
// export var ossAccessid = 'LTAI5tFZdCKybTxQmG5pWGhN';
// export var ossAccesskey = 'YYkVHYcmlZlOKfbCcJ8PVAeQcDivrP';
// export var ossHost = 'https://zjmeifeng.oss-cn-beijing.aliyuncs.com/';

var ossAccessid = "LTAI5tK9iT8pfTv5WfpaCF9S"
var ossAccesskey = "3TU0tCBVUnTAZFmTIjPuJkBJxxeH0q"
export var ossHost = "https://meifeier.oss-accelerate.aliyuncs.com/";
export var ossPolicyBase64 = Base64.encode(JSON.stringify(policyText));
export var message = ossPolicyBase64;
export var bytes = Crypto.HMAC(Crypto.SHA1, message, ossAccesskey, {
    asBytes: true
});

export var ossSignature = Crypto.util.bytesToBase64(bytes);

// 引入方式:
export const uploader = (url, type) => {
    let imgDate = new Date().getTime()
    let imgKey
    let fileType = "image"
    if (!type) {
        imgKey = "uniapp_c/image_" + imgDate + "_" + Math.random().toString(36).substr(2) + ".jpg"
    } else {
        imgKey = "uniapp_c/video_" + imgDate + "_" + Math.random().toString(36).substr(2) + ".mp4"
        fileType = "video"
    }
    let formData = new FormData()
    formData.append("OSSAccessKeyId", ossAccessid)
    formData.append("policy", ossPolicyBase64)
    formData.append("signature", ossSignature)
    formData.append("success_action_status", 200)
    formData.append("key", imgKey)
    formData.append("file", url)
    return new Promise((resolve, reject) => {
        axios({
            url: ossHost,
            fileType: fileType,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: "post",
            name: "file",
        }).then(res => {
            let {
                statusCode
            } = res
            let obj = {
                ImgUrl: ossHost + imgKey,
                statusCode: statusCode,
                url: '/' + imgKey
            }
            resolve(obj)
        }).catch((err) => {
            console.log(err)
            reject({
                statusCode: 999
            })
        })
    })
}