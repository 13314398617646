<template>
  <div class="step-form">
    <div class="step-item">
      <div class="item-title">1.医生资格证书</div>
      <div style="width: 850px; display: flex; margin: 10px 0">
        <div class="ident-area">
          <img :src="src1" @click="click1" class="ident-img" />
          <span class="ident-text">上传资格证首页</span>
          <input ref="chooseIMG1" style="display: none" type="file" @change="getFile1" />
        </div>
        <div class="ident-area">
          <img :src="src2" class="ident-img" @click="click2" />
          <span class="ident-text">上传资格证详情页</span>
          <input ref="chooseIMG2" style="display: none" type="file" @change="getFile2" />
        </div>
      </div>
    </div>
    <div class="step-item">
      <div class="item-title">2.医生执业证书</div>
      <div style="width: 850px; display: flex; margin: 10px 0">
        <div class="ident-area">
          <img :src="src3" class="ident-img" @click="click3" />
          <span class="ident-text">上传执业证首页</span>
          <input ref="chooseIMG3" style="display: none" type="file" @change="getFile3" />
        </div>
        <div class="ident-area">
          <img :src="src4" class="ident-img" @click="click4" />
          <span class="ident-text">上传执业证姓名与注册地址页</span>
          <input ref="chooseIMG4" style="display: none" type="file" @change="getFile4" />
        </div>
        <div class="ident-area">
          <img :src="src5" class="ident-img" @click="click5" />
          <span class="ident-text">上传执业更新变更页（无变更史则不上传）</span>
          <input ref="chooseIMG5" style="display: none" type="file" @change="getFile5" />
        </div>
      </div>
    </div>
    <div class="step-item">
      <div class="item-title">3.职业资格证书</div>
      <div style="width: 850px; display: flex; margin: 10px 0">
        <div class="ident-area">
          <img :src="src6" class="ident-img" @click="click6" />
          <span class="ident-text">上传职业资格证首页</span>
          <input ref="chooseIMG6" style="display: none" type="file" @change="getFile6" />
        </div>
        <div class="ident-area">
          <img :src="src7" class="ident-img" @click="click7" />
          <span class="ident-text">上传职业证姓名与注册地址页</span>
          <input ref="chooseIMG7" style="display: none" type="file" @change="getFile7" />
        </div>
      </div>
    </div>
    <div class="form-item">
      <div style="width: 100px">编号:</div>
      <b-form-input
        placeholder="请输入16位证件编码"
        v-model="detail.bn"
        style="width: 400px"
        maxlength="16"
      ></b-form-input>
    </div>
    <div class="form-item">
      <div style="width: 100px">有效期:</div>
      <!-- <b-form-input placeholder="请输入有效期" v-model="endtime" style="width: 360px"></b-form-input> -->
      <b-form-datepicker
        placeholder="选择有效期"
        v-model="value"
        @context="onContext"
        calendar-width="500"
        style="width: 400px"
      ></b-form-datepicker>
    </div>
    <div class="form-submit">
      <div class="form-btn" @click="back" style="margin-right: 20px">上一步</div>
      <div class="form-btn" @click="finish">
        <b-icon
          icon="arrow-counterclockwise"
          animation="spin-reverse-pulse"
          v-show="loading"
        ></b-icon>
        提交审核
      </div>
    </div>
  </div>
</template>
<script>
import { uploader } from "../../common/ossUploadfile.js";
export default {
  data() {
    return {
      src1: require("../../static/join/doctor_img_certificate@2x.png"),
      src2: require("../../static/join/doctor_img_certificate@2x.png"),
      src3: require("../../static/join/doctor_img_certificate@2x.png"),
      src4: require("../../static/join/doctor_img_certificate@2x.png"),
      src5: require("../../static/join/doctor_img_certificate@2x.png"),
      src6: require("../../static/join/doctor_img_certificate@2x.png"),
      src7: require("../../static/join/doctor_img_certificate@2x.png"),
      detail: {
        p_image: "", //资格证首页
        d_image: "", //资格证详情
        w_p_image: "", //执业证
        w_d_image: "", //执业证详情
        p_h_image: "",
        p_d_image: "",
        // w_u_image: "", //职业变更
        bn: "", //编号
        endtime: "", //有效期
      },
      value: "",
      loading: false,
    };
  },
  methods: {
    finish() {
      if (this.loading) {
        return;
      }
      if (this.detail.p_image == "" || this.detail.d_image == "") {
        return this.$store.commit("showToast", "请上传医生资格证!");
      }
      if (this.detail.w_p_image == "" || this.detail.w_d_image == "") {
        return this.$store.commit("showToast", "请上传医生执业证书!");
      }
      if (this.detail.p_d_image == "" || this.detail.p_h_image == "") {
        return this.$store.commit("showToast", "请上传职业资格证书!");
      }
      if (this.detail.bn.length < 16 || this.detail.endtime == "") {
        return this.$store.commit("showToast", "请输入16位证件编号、有效期!");
      }
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
      this.$emit("finish", this.detail);
    },
    onContext(ctx) {
      this.detail.endtime = ctx.selectedDate / 1000;
    },
    click1() {
      this.$refs.chooseIMG1.dispatchEvent(new MouseEvent("click"));
    },
    click2() {
      this.$refs.chooseIMG2.dispatchEvent(new MouseEvent("click"));
    },
    click3() {
      this.$refs.chooseIMG3.dispatchEvent(new MouseEvent("click"));
    },
    click4() {
      this.$refs.chooseIMG4.dispatchEvent(new MouseEvent("click"));
    },
    click5() {
      this.$refs.chooseIMG5.dispatchEvent(new MouseEvent("click"));
    },
    click6() {
      this.$refs.chooseIMG6.dispatchEvent(new MouseEvent("click"));
    },
    click7() {
      this.$refs.chooseIMG7.dispatchEvent(new MouseEvent("click"));
    },
    getFile1() {
      let inputFile = this.$refs.chooseIMG1.files[0];
      uploader(inputFile).then((res) => {
        this.src1 = res.ImgUrl;
        this.detail.p_image = res.url;
      });
      console.log(inputFile);
    },
    getFile2() {
      let inputFile = this.$refs.chooseIMG2.files[0];
      uploader(inputFile).then((res) => {
        this.src2 = res.ImgUrl;
        this.detail.d_image = res.url;
      });
    },
    getFile3() {
      let inputFile = this.$refs.chooseIMG3.files[0];
      uploader(inputFile).then((res) => {
        this.src3 = res.ImgUrl;
        this.detail.w_p_image = res.url;
      });
    },
    getFile4() {
      let inputFile = this.$refs.chooseIMG4.files[0];
      uploader(inputFile).then((res) => {
        this.src4 = res.ImgUrl;
        this.detail.w_d_image = res.url;
      });
    },
    getFile5() {
      let inputFile = this.$refs.chooseIMG5.files[0];
      uploader(inputFile).then((res) => {
        this.src5 = res.ImgUrl;
        // this.detail.p_h_image = res.url
        //   this.detail.w_d_image = res.url
      });
    },
    getFile6() {
      let inputFile = this.$refs.chooseIMG6.files[0];
      uploader(inputFile).then((res) => {
        this.src6 = res.ImgUrl;
        //   this.detail.w_d_image = res.url
        this.detail.p_h_image = res.url;
      });
    },
    getFile7() {
      console.log("1");
      let inputFile = this.$refs.chooseIMG7.files[0];
      uploader(inputFile).then((res) => {
        this.src7 = res.ImgUrl;
        this.detail.p_d_image = res.url;
      });
    },
    back() {
      this.$emit('back')
    }
  },
};
</script>
<style lang="scss" scoped>
.step-form {
  padding: 0 10px;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  margin: 10px 0;
  width: 840px;
  height: 50px;
  align-items: center;
}
.step-item {
  margin: 15px 0;
  width: 850px;
  display: flex;
  flex-wrap: wrap;
  .item-title {
    font-size: 14px;
    color: #666;
    width: 850px;
    height: 32px;
    line-height: 32px;
  }
  .ident-text {
    color: #999;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
  }
  .ident-area {
    width: 250px;
    margin-right: 40px;
    text-align: center;
    .ident-img {
      width: 250px;
      height: 160px;
      object-fit: cover;
    }
  }

  .ident-area2 {
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin: 10px 0;
    .ident-item {
      width: 161px;
      height: 96px;
      border-radius: 4px;
    }
    .sure-bg {
      width: 21px;
      margin-left: 70px;
      margin-top: 5px;
      text-align: center;
      height: 21px;
      border-radius: 50%;
      z-index: 999;
      line-height: 21px;
      color: #fff;
    }
    .txt-center {
      text-align: center;
      width: 161px;
    }
    .error-icon {
      color: red;
      font-size: 21px;
      margin-top: 5px;
    }
    .check-icon {
      color: #3289ed;
      font-size: 21px;
      margin-top: 5px;
    }
  }
  .form-submit {
    width: 850px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    .form-btn {
      background: #fd9852;
      color: #fff;
      text-align: center;
      width: 131px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
    }
    .form-btn:hover {
      cursor: pointer;
    }
  }
}
</style>
