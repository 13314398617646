import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from "./router"
import cache from "./common/state.js"
import createHttp from "./api/api.js"
import request from "./api/doctor_api.js"
import Toast from './utils/Toast.vue'
import store from "./store.js"
import timeFormat from './common/timeFormat'
import Empty from "./utils/Empty.vue"
import axios from "axios"
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.config.productionTip = false
Vue.prototype.$cache = cache
Vue.prototype.$http = createHttp
Vue.prototype.$request = request
Vue.prototype.$store = store
Vue.prototype.$timeFormat = timeFormat
Vue.prototype.$axios = axios
Vue.prototype.$EventBus = new Vue()
Vue.component('Toast', Toast)
Vue.component('Empty', Empty)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')