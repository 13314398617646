<template>
  <div class="bottom">
    <div class="icons-div">
      <div class="meifeng">
        <div class="title">赋能美业未来，美蜂为美而生</div>
        <div class="desc">Meifeng Doctor</div>
        <div class="ul">
          <div class="iconD">
            <img src="../static/home/home_ico_mechanism.png" class="icon" />
            <div class="icon-title">资质机构</div>
          </div>
          <div class="iconD">
            <img src="../static/home/home_ico_doctor.png" class="icon" />
            <div class="icon-title">在线名医</div>
          </div>
          <div class="iconD">
            <img src="../static/home/home_ico_refund.png" class="icon" />
            <div class="icon-title">闪电退款</div>
          </div>
          <div class="iconD">
            <img src="../static/home/home_ico_price.png" class="icon" />
            <div class="icon-title">价格透明</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" >
      <div class="item">
        <div class="title">关于我们</div>
        <div><b-link class="link">关于美蜂</b-link></div>
        <div><b-link class="link">联系我们</b-link></div>
        <div><b-link class="link">关注我们</b-link></div>
      </div>
      <div class="item">
        <div class="title">快速访问</div>
        <div>
          <b-link class="link" style="margin-right: 30px">首页</b-link>
          <b-link class="link">商城</b-link>
        </div>
        <div>
          <b-link class="link" style="margin-right: 30px">社区</b-link>
          <b-link class="link">关于我们</b-link>
        </div>
        <div>
          <b-link class="link" style="margin-right: 30px">医生</b-link>
          <b-link class="link">新闻中心</b-link>
        </div>
      </div>
      <div class="bg-item" style="width: 240px">
        <div class="title">联系我们</div>
        <div>
          <b-link class="link">
            <b-icon icon="telephone-fill" style="margin-right: 10px"></b-icon
            >电话：400-851-5180
          </b-link>
        </div>
        <div>
          <b-link class="link"
            ><b-icon icon="geo-alt-fill" style="margin-right: 10px"></b-icon
            >地点:浙江杭州西湖区紫荆花路联合大厦A座</b-link
          >
        </div>
      </div>
      <div class="item">
        <div class="title">关注我们</div>
        <div>
          <img src="../static/home/wechat.jpg" style="width: 100px; height: 100px" />
        </div>
      </div>
      <div class="item">
        <div class="title">App下载享优惠</div>
        <div>
          <img src="../static/images/down.png" style="width: 100px; height: 100px" />
        </div>
      </div>
    </div>
    <div class="srive">
      <div style="height: 32px; line-height: 32px">
        Copyright©2021 浙江美菲儿科技集团有限公司©版权所有 |
        互联网药品信息服务资格证书-（浙）-经营性-2021-0110
      </div>
      <div style="height: 32px; line-height: 32px">
        用户投诉与建议邮箱：1015224731@qq.com
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.icons-div {
  width: 100%;
  background: #f6f9f9;
  height: 291px;
  display: flex;
  justify-content: center;
  .meifeng {
    width: 1200px;
    .title {
      font-size: 32px;
      text-align: center;
      line-height: 48px;
      margin-top: 24px;
      width: 100%;
      color: #666;
    }
    .desc {
      font-size: 16px;
      text-align: center;
      height: 32px;
      line-height: 32px;
      color: #999;
    }
    .ul {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;
      .iconD {
        width: 131px;
        height: 127px;
        border: 1px solid #cecece;
        text-align: center;
      }
      .icon {
        width: 43px;
        height: 50px;
        margin-top: 24px;
      }
      .icon-title {
        line-height: 50px;
        font-size: 18px;
        color: #666;
      }
    }
  }
}
.bottom {
  width: 100%;
  height: 630px;
//   padding-top: 39px;
  background: #0d0f1b;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .content {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    height: 200px;
    padding-top: 9px;
    .item,
    .bg-item {
      width: 180px;
      height: 200px;
      color: #fff;
      .title {
        font-size: 20px;
        color: #cecece;
        line-height: 40px;
        margin-bottom: 10px;
      }
      .link {
        font-size: 16px;
        line-height: 32px;
        color: #999;
        text-decoration: none;
      }
    }
  }
  .srive {
    width: 100%;
    border-top: 1px solid #d1d1d1;
    height: 99px;
    padding: 18px 0;
    text-align: center;
    color: #999999;
  }
}
</style>
