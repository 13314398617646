import { http } from "./request.js"
export const sendCode = (option) => {
    return http({
        url: '/api/doctors.sms/send',
        method: 'get',
        params: option
    })
}

// GET 会员登录 /api/doctors.doctors/login
export const login = (option) => {
    return http({
        url: '/api/doctors.doctors/login',
        method: 'get',
        params: option
    })
}

//手机验证码登录
export const mobileLogin = (option) => {
    return http({
        url: '/api/doctors.doctors/mobilelogin',
        method: 'get',
        params: option
    })
}

//注销账号 /api/doctors.doctors/closeaccount
export const checkAccount = (option) => {
    return http({
        url: '/api/doctors.doctors/closeaccount',
        method: 'get',
        params: option
    })
}

// GET 医生职称列表 /api/doctors.base/position
export const position = (option) => {
    return http({
        url: '/api/doctors.base/position',
        method: 'get',
        params: option
    })
}

// GET 医生擅长项目 /api/doctors.base/collections
export const collections = (option) => {
    return http({
        url: '/api/doctors.base/collections',
        method: 'get',
        params: option
    })
}

//医生信息 /doctors.doctors/index
export const getDoctorInfo = (option) => {
    return http({
        url: '/api/doctors.doctors/index',
        method: 'get',
        params: option
    })
}

//获取首页信息 /api/msg.message/myclinic
export const getonline = (option) => {
    return http({
        url: '/api/msg.message/myclinic',
        method: 'get',
        params: option
    })
}

// 预约表 /api/doctors.doctors/appointmentlist
export const appointmentlist = (option) => {
    return http({
        url: '/api/doctors.doctors/appointmentlist',
        method: 'get',
        params: option
    })
}

//操作手册分类 /api/doctors.manual/index
export const cate = (option) => {
    return http({
        url: '/api/doctors.manual/index',
        method: 'get',
        params: option
    })
}

// GET 文档列表 /api/doctors.manual/listcontent
export const listcontent = (option) => {
    return http({
        url: '/api/doctors.manual/listcontent',
        method: 'get',
        params: option
    })
}

// POST 资质认证 /api/doctors.ident/certification
export const certification = (option) => {
    return http({
        url: '/api/doctors.ident/certification',
        method: 'post',
        data: option
    })
}

// GET 资质证件 /api/doctors.doctors/working
export const working = (option) => {
    return http({
        url: '/api/doctors.doctors/working',
        method: 'get',
        params: option
    })
}

// GET 医院列表接口 /api/doctors.base/hospitallist
export const gethospitallist = (option) => {
    return http({
        url: '/api/doctors.base/hospitallist',
        method: 'get',
        params: option
    })
}

//GET 已入驻列表 /api/doctors.base/applyList
export const applyList = (option) => {
    return http({
        url: '/api/doctors.base/applyList',
        method: 'get',
        params: option
    })
}

// GET 医院详情 /api/doctors.base/hospitaldetail
export const hospitalinfo = (option) => {
    return http({
        url: '/api/doctors.base/hospitaldetail',
        method: 'get',
        params: option
    })
}

// POST 入驻医院 /api/doctors.base/apply 
export const joinhospital = (option) => {
    return http({
        url: '/api/doctors.base/apply',
        method: 'post',
        data: option
    })
}

//GET 取消入驻 /api/doctors.base/cancelApply
export const cancelApply = (option) => {
    return http({
        url: '/api/doctors.base/cancelApply',
        method: 'get',
        params: option
    })
}

// GET 科普列表 /api/doctors.science/listcontent
export const sciencelist = (option) => {
    return http({
        url: "/api/doctors.science/listcontent",
        method: "get",
        params: option
    })
}

// GET 科普标签列表 /api/doctors.science/caterule
export const caterule = (opt) => {
    return http({
        url: "/api/doctors.science/caterule",
        method: "get",
        params: opt
    })
}

// GET 订单列表 /api/doctors.advisoryorders/orderslist
export const orderslist = (opt) => {
    return http({
        url: "/api/doctors.advisoryorders/orderslist",
        method: "get",
        params: opt
    })
}

//系统消息 /api/msg.message/index
export const message = (opt) => {
    return http({
        url: "/api/msg.message/index",
        method: "get",
        params: opt
    })
}

//评论与@  /api/msg.message/detail
export const chats = (opt) => {
    return http({
        url: "/api/msg.message/detail",
        method: "get",
        params: opt
    })
}

// GET 到院就诊订单列表 /api/doctors.orders/orderlist
export const orderlist = (opt) => {
    return http({
        url: "/api/doctors.orders/orderlist",
        method: "get",
        params: opt
    })
}

export default {
    sendCode,
    login,
    mobileLogin,
    checkAccount,
    position,
    collections,
    getDoctorInfo,
    getonline,
    appointmentlist,
    cate,
    listcontent,
    certification,
    working,
    gethospitallist,
    applyList,
    hospitalinfo,
    joinhospital,
    cancelApply,
    sciencelist,
    caterule,
    orderslist,
    message,
    chats,
    orderlist
}