import Vue from "vue"
import axios from "axios"
import Qs from "qs"
import { getCache } from "../common/state.js"
import router from "../router.js"
import store from "../store.js"
const baseURL = "https://testwww.meifengmingyi.com"
    // const baseURL = "https://www.meifengmingyi.com" //正式
Vue.prototype.$baseURL = baseURL

function getToken() {
    let token = ""
    if (getCache("doctorInfo")) {
        token = JSON.parse(getCache("doctorInfo")).token
        console.log(token)
    }
    return token
}
const instance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "token": ""
    },
    method: "get",
    withCredentials: true,
    paramsSerializer: function(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' })
    },
})


instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export const http = (option) => {
    instance.defaults.headers.token = getToken()
    return new Promise((resolve, reject) => {
        instance(option).then(response => {
            resolve(response.data)
        }).catch(err => {
            console.log(err.response.status)
            if (err.response.status == 401) {
                store.commit("showToast", "请登录后操作")
                setTimeout(() => {
                    router.replace({ path: '/doctorLogin' });
                }, 2000)
            }
            reject(err)
        })
    })
}