<template>
  <div class="page">
    <div class="content">
      <div class="left">
        <div class="top">
          <div>
            <div class="logo">美蜂</div>
            <div class="logo-text">美蜂名医</div>
          </div>
        </div>
        <div class="bottom">
          <!-- <div class="option">
            <img class="icon" src="../static/images/qq.png" />
            <span>QQ登录</span>
          </div>
          <div>|</div>
          <div class="option">
            <img class="icon" src="../static/images/weixin.png" />
            <span>微信登录</span>
          </div> -->
          <div style="margin-right: 30px">
            <div class="link" v-show="type == 'Login'" @click="changePage('Login')">
              <span>立即注册</span> <b-icon icon="chevron-compact-right"></b-icon>
            </div>
            <div class="link" v-show="type == 'Register'" @click="changePage('Register')">
              <span>立即登录</span> <b-icon icon="chevron-compact-right"></b-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "",
    };
  },
  methods: {
    showPage() {},
    changePage(event) {
      if (event == "Login") {
        this.type = "Register";
        this.$router.push({ path: "/login/register" });
      } else {
        this.type = "Login";
        this.$router.push({ path: "/login" });
      }
    },
  },
  created() {
    console.log(this.$route.name);
    this.type = this.$route.name;
  },
  computed: {},
  watch: {
    check: (val) => {
      console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background: url("../static/images/header-app-image.jpg") no-repeat;
  background-size: 100vw;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 940px;
  height: 612px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  .left {
    width: 50%;
    height: 612px;
    background: #ff984e;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    .top {
      height: 540px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bottom {
      height: 72px;
      display: flex;
      align-items: center;
      line-height: 72px;
      color: #fff;
      padding-left: 20px;
      justify-content: flex-end;
      .option {
        margin: 0 10px;
      }
    }
  }
  .right {
    width: 50%;
    height: 612px;
  }
}
.logo {
  width: 100px;
  height: 100px;
  border-radius: 24px;
  line-height: 100px;
  text-align: center;
  background: #fff;
  font-size: 40px;
  color: #ff984e;
}
.logo-text {
  width: 100px;
  margin: 5px 0;
  text-align: center;
  color: #fff;
  font-size: 24px;
}
.icon {
  width: 24px;
  height: 24px;
}
.link {
  text-decoration: none;
  color: #fff;
}
.link:hover {
  cursor: pointer;
}
</style>
