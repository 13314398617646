<template>
  <div>
    <div class="city-name">热门城市</div>
    <div class="city-area">
      <div class="city-select" @click="select('全国', null)">全国</div>
      <div
        class="city-select"
        v-for="(city, index) in hotCity"
        :key="index"
        v-show="city.id != 0"
        @click="select(city.name, city.id)"
      >
        {{ city.name }}
      </div>
    </div>

    <div class="index-list">
      <div
        class="index-item"
        v-for="item in indexList"
        :key="item"
        :class="indexActive == item ? 'index-active' : ''"
        @click="selectIndex(item)"
      >
        {{ item }}
      </div>
    </div>

    <div class="citys-list">
      <div class="sort-area">{{ city.ch }}</div>
      <div class="data-area">
        <div
          class="data-select"
          v-for="(item, index) in city.ul"
          :key="index"
          @click="select(item.text, item.value)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { citys } from "../common/citys.js";
export default {
  data() {
    return {
      indexList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      indexActive: "A",
      hotCity: [],
      citys: citys,
      city: {},
    };
  },
  mounted() {
    this.getHots();
    this.getCitys();
  },
  methods: {
    selectIndex(item) {
      this.indexActive = item;
      this.getCitys(item);
    },
    getHots() {
      this.$http.hot({}).then((res) => {
        let { data, code } = res;
        if (code == 1) {
          this.hotCity = data;
        }
      });
    },
    getCitys(event) {
      let obj = event ? event : this.indexActive;
      this.citys.forEach((item) => {
        if (item.ch == obj) {
          this.city = item;
        }
      });
      console.log(this.city);
    },
    select(name, id) {
      let data = {
        name: name,
        id: id,
      };
      this.$emit("selectcity", data);
    },
  },
};
</script>
<style scoped lang="scss">
.city-name {
  height: 28px;
  font-size: 14px;
  color: #333;
  line-height: 28px;
  font-weight: bold;
}
.city-area {
  // height: 56px;
  line-height: 28px;
  color: #999;
  display: flex;
  flex-wrap: wrap;
  .city-select {
    color: #999;
    margin-right: 20px;
  }
  .city-select:hover {
    cursor: pointer;
  }
}
.index-list {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  .index-item {
    margin-right: 20px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: #f6f4f4;
    text-align: center;
    font-size: 12px;
    color: #999;
    line-height: 20px;
    margin-top: 10px;
  }
  .index-item:hover {
    cursor: pointer;
  }
  .index-active {
    // color: #fb776a;
    background: #fd9852;
    color: #fff;
  }
}
.citys-list {
  margin-top: 15px;
  display: flex;
  font-size: 12px;
  // flex-wrap: wrap;
  .sort-area {
    width: 40px;
    text-align: left;
    line-height: 24px;
    font-weight: bold;
    color: #333;
  }
  .data-area {
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
    color: #999;
    width: 520px;
    .data-select {
      margin-right: 20px;
    }
    .data-select:hover {
      cursor: pointer;
    }
  }
}
</style>
