<template>
  <div class="step-form">
    <div class="form-item">
      <div class="form-label">上传头像</div>
      <div class="form-value">
        <img class="upload-img" :src="avatar" @click="checkFile" />
      </div>
      <input type="file" style="display: none" ref="file" @change="getFileData" />
    </div>
    <div class="form-item-inline mg-right">
      <div class="form-label">姓名</div>
      <div class="form-value">
        <b-form-input
          type="text"
          v-model="detail.nickname"
          placeholder="请输入真实姓名，15字以内"
        ></b-form-input>
      </div>
    </div>
    <div class="form-item-inline">
      <div class="form-label">性别</div>
      <div class="form-value" style="display: flex">
        <b-form-radio
          v-model="detail.gender"
          name="some-radios"
          value="1"
          style="margin-right: 20px"
          >男</b-form-radio
        >
        <b-form-radio v-model="detail.gender" name="some-radios" value="0"
          >女</b-form-radio
        >
      </div>
    </div>
    <div class="form-item-inline mg-right">
      <div class="form-label">联系方式</div>
      <div class="form-value">
        <b-form-input
          type="text"
          v-model="detail.mobile"
          placeholder="请输入联系方式"
          maxlength="11"
        ></b-form-input>
      </div>
    </div>
    <div class="form-item-inline">
      <div class="form-label">执业机构</div>
      <div class="form-value">
        <b-form-input
          type="text"
          readonly
          @click="modalShow = !modalShow"
          placeholder="请输入机构名称"
          v-model="detail.workingagency"
        ></b-form-input>
      </div>
    </div>
    <div class="form-item-inline mg-right">
      <div class="form-label">当前职称</div>
      <div class="form-value">
        <b-form-select class="form-select" v-model="detail.position_id">
          <b-form-select-option value="">请选择职称</b-form-select-option>
          <b-form-select-option
            v-for="(item, index) in position"
            :key="index"
            :value="item.id"
            >{{ item.name }}</b-form-select-option
          >
        </b-form-select>
      </div>
    </div>
    <div class="form-item-inline">
      <div class="form-label">从业时间</div>
      <div class="form-value">
        <b-form-datepicker
          placeholder="选择从业时间"
          v-model="value"
          @context="onContext"
          class="mb-2"
          calendar-width="500"
        ></b-form-datepicker>
      </div>
    </div>
    <div class="form-item">
      <div class="form-label">擅长项目</div>
      <div class="form-value">
        <b-form-input
          placeholder="请选择擅长项目"
          readonly
          v-b-toggle.collapse
          v-model="detail.selfthing"
        ></b-form-input>
        <b-collapse id="collapse" class="form-check-area">
          <b-form-checkbox
            class="form-check-item"
            v-for="(item, index) in goods"
            :key="index"
            v-model="selected"
            name="select"
            :value="index"
          >
            <span style="padding-left: 2.5px">{{ item.collection_name }}</span>
          </b-form-checkbox>
        </b-collapse>
      </div>
    </div>
    <div class="form-item">
      <div class="form-label">个人简介</div>
      <div style="width: 750px">
        <b-form-textarea
          placeholder="个人简介"
          rows="3"
          v-model="detail.description"
        ></b-form-textarea>
      </div>
    </div>
    <div class="form-submit">
      <div class="form-btn" @click="next">下一步</div>
    </div>
    <b-modal
      v-model="modalShow"
      hide-header-close
      hide-header
      size="lg"
      centered
      hide-footer
    >
      <div class="custom-model-header">
        <div class="header-before"></div>
        选择执业机构
      </div>
      <div class="custom-modal-content">
        <div class="modal-item">
          <div style="width: 100px">机构名称：</div>
          <b-form-input
            style="width: 530px"
            placeholder="请输入机构名称"
            v-model="detail.workingagency"
          ></b-form-input>
        </div>
        <div class="modal-item">
          <div style="width: 100px">机构地区：</div>
          <b-form-select v-model="area.province" class="custom-select">
            <b-form-select-option value="">请选择省份</b-form-select-option>
            <b-form-select-option
              v-for="(item, index) in region"
              :key="index"
              :value="item.provinceName"
              >{{ item.provinceName }}</b-form-select-option
            >
          </b-form-select>
          <b-form-select v-model="area.city" class="custom-select">
            <b-form-select-option value="">请选择城市</b-form-select-option>
            <b-form-select-option
              v-for="(item, index) in cityList"
              :key="index"
              :value="item.cityName"
              >{{ item.cityName }}</b-form-select-option
            >
          </b-form-select>
          <b-form-select v-model="area.area" class="custom-select">
            <b-form-select-option value="">请选择区/县</b-form-select-option>
            <b-form-select-option
              v-for="(item, index) in areaList"
              :key="index"
              :value="item.areaName"
              >{{ item.areaName }}</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="modal-item">
          <div style="width: 100px">详细地址：</div>
          <b-form-input
            style="width: 530px"
            placeholder="请输入详细地址"
            v-model="detail.workingagency_address"
          ></b-form-input>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { uploader } from "../../common/ossUploadfile";
import { region } from "../../common/region";
const testReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
export default {
  data() {
    return {
      value: "",
      avatar: require("../../static/join/doctor_ico_upload-image@2x.png"),
      detail: {
        avatar: "",
        position_id: "",
        collection_ids: "", //擅长项目ID
        gender: 0,
        mobile: "",
        nickname: "",
        description: "",
        workingtime: "",
        selfthing: "",
        workingagency: "",
        workingagency_area: "",
        workingagency_address: "", //详细地址
      },
      area: {
        province: "",
        city: "",
        area: "",
      },
      cityList: [],
      position: [],
      goods: [],
      selected: [],
      modalShow: false,
      region: region,
      areaList: [],
    };
  },
  mounted() {
    this.getPosition();
    this.getGoodat();
  },
  watch: {
    selected: function (val) {
      let arr = [];
      let ids = [];
      val.forEach((item) => {
        arr.push(this.goods[item].collection_name);
        ids.push(this.goods[item].id);
      });
      this.detail.selfthing = arr.join();
      this.detail.collection_ids = ids.join();
    },
    "area.province": function (val) {
      this.region.forEach((item) => {
        if (val == item.provinceName) {
          this.cityList = item.mallCityList;
        }
      });
      this.areaList = [];
      this.area.city = "";
      this.area.area = "";
    },
    "area.city": function (val) {
      this.cityList.forEach((item) => {
        if (item.cityName == val) {
          this.areaList = item.mallAreaList;
        }
      });
      this.area.area = "";
    },
  },
  methods: {
    getPosition() {
      this.$request.position({}).then((res) => {
        // console.log(res)
        let { data, code, msg } = res;
        if (code == 1) {
          this.position = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getGoodat() {
      this.$request.collections({}).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.goods = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    onContext(ctx) {
      this.value = ctx.selectedYMD
      this.detail.workingtime = ctx.selectedDate / 1000;
    },
    next() {
      if (
        this.detail.nickname == "" ||
        this.detail.position_id == "" ||
        this.detail.workingtime == ""
      ) {
        return this.$store.commit("showToast", "请输入姓名、职称、从业时间等内容");
      }

      if (this.detail.collection_ids == "") {
        return this.$store.commit("showToast", "请选择擅长项目");
      }

      if (!testReg.test(this.detail.mobile)) {
        return this.$store.commit("showToast", "请输入正确的手机号码格式");
      }

      if (
        this.area.province == "" ||
        this.area.city == "" ||
        this.area.area == "" ||
        this.detail.workingagency_address == "" ||
        this.detail.workingagency == ""
      ) {
        return this.$store.commit("showToast", "请补充完整的机构信息");
      }
      this.detail.workingagency_area =
        this.area.province + "-" + this.area.city + "-" + this.area.area;
      this.$emit("next", this.detail);
    },
    checkFile() {
      this.$refs.file.dispatchEvent(new MouseEvent("click"));
    },
    getFileData() {
      let fileData = this.$refs.file.files[0];
      uploader(fileData).then((res) => {
        console.log(res);
        this.avatar = res.ImgUrl;
        this.detail.avatar = res.url;
      });
    },
    selectGoods(opt) {
      console.log(opt);
      if (!this.goods[opt].check) {
        this.goods[opt].check = true;
      } else {
        this.goods[opt].check = false;
      }
    },
  },
};
</script>
<style lang="scss" scope>
.step-form {
  padding: 0 10px;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  .upload-img {
    width: 128px;
    height: 128px;
  }
  .upload-img:hover {
    cursor: pointer;
  }
  .form-item {
    display: flex;
    margin: 15px 0;
    width: 840px;
  }
  .form-item-inline {
    width: 400px;
    display: flex;
    margin: 15px 0;
  }
  .form-label {
    width: 85px;
  }
  .mg-right {
    margin-right: 50px;
  }
  .form-value {
    width: 305px;
  }

  .form-select {
    height: 38px;
    border: 1px solid #ced4da;
    .select-option {
      height: 32px;
      line-height: 32px;
    }
  }
  .form-check-area {
    width: 305px;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    padding: 10px 10px;
    margin-top: 5px;
  }
  .form-check-item {
    height: 32px;
    width: 81px;
    white-space: nowrap;
    line-height: 32px;
    font-size: 14px;
    margin-right: 10px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .form-check-item:hover {
    cursor: pointer;
  }
  .form-check-item-active {
    background: #fd9852;
    color: #fff;
    border: none;
  }
  .form-submit {
    width: 850px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    .form-btn {
      background: #fd9852;
      color: #fff;
      text-align: center;
      width: 131px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
    }
    .form-btn:hover {
      cursor: pointer;
    }
  }
}
.custom-model-header {
  display: flex;
  align-items: center;
  height: 32px;
  .header-before {
    height: 16px;
    width: 4px;
    background: #698ef8;
    margin-right: 4px;
    border-radius: 1px;
  }
}
.custom-modal-content {
  .modal-item {
    height: 60px;
    display: flex;
    align-items: center;
  }
}
.custom-select {
  height: 38px;
  border: 1px solid #ced4da;
  width: 170px;
  margin-right: 10px;
}
</style>
